<template>
	<div class="app-container" :style="{direction: yuyan.now=='hanyu'?'ltr':'rtl',textAlign: yuyan.now=='hanyu'? 'left':'right'}">

		<el-card style="margin-top: 20px">

			<div class="qu-content">

				<p style="font-size: 18px;">【{{ quData.quType===1?yuyan.start_answer_dan:yuyan.start_answer_duo }}】{{ quData.content }}</p>
				<div v-if="images.length!==0">
					<el-image v-for="item in images" style="height: 300px;width: auto;"
					:preview-src-list="images"
						:src="item" fit="contain"></el-image>
				</div>
				<div v-if="quData.quType === 1">
					<el-radio-group v-model="radioValues" readonly>
						<el-radio style="margin-right: 10px;" v-for="an in quData.answerList" :label="an.id" readonly>{{ an.content }}
						<div v-if="an.image!==''" style="display: flex;flex-wrap: wrap;">
							<el-image
								:preview-src-list="[imgShow(an.image)]"
								style="height: 150px;width: auto;"
								:src="imgShow(an.image)" fit="contain">
							</el-image>
						</div>
						</el-radio>
					</el-radio-group>
				</div>

				<!-- 多选题 -->
				<div v-if="quData.quType === 2">
					<el-checkbox-group v-model="multiValues" readonly>
						<el-checkbox v-for="an in quData.answerList" :label="an.id">{{ an.content }}
						
						<div>
							<el-image
								:preview-src-list="[imgShow(an.image)]"
								style="height: 150px;width: auto;"
								:src="imgShow(an.image)" fit="contain">
							</el-image>
						</div></el-checkbox>
					</el-checkbox-group>
				</div>

			</div>

		</el-card>

		<el-card class="qu-analysis" style="margin-top: 20px">
			{{yuyan.ViewQu_jiexi}}：
			<p>{{ quData.analysis?quData.analysis:'' }}</p>
			<p v-if="!quData.analysis">{{yuyan.ViewQu_no_jiexi}}！</p>
		</el-card>

		<el-card class="qu-analysis" style="margin-top: 20px; margin-bottom: 30px">
			<div v-for="an in quData.answerList">
				<div v-if="an.analysis" class="qu-analysis-line">
					<p style="color: #555;">{{ an.content }}：</p>
					<p style="color: #1890ff;">{{ an.analysis }}</p>
				</div>
			</div>
			<p v-if="analysisCount === 0">{{yuyan.ViewQu_no_choosejiexi}}</p>

		</el-card>

		<el-button type="info" @click="onCancel">{{yuyan.fanhui}}</el-button>

	</div>
</template>

<script>
	import {
		fetchDetail
	} from '@/api/qu/qu'

	export default {
		name: 'QuView',
		data() {
			return {
				images: [],
				yuyan: {},
				quData: {

				},

				radioValues: '',
				multiValues: [],
				analysisCount: 0

			}
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},
		created() {
			this.yuyan = this.$language()
			const id = this.$route.query.id
			if (typeof id !== 'undefined') {
				this.fetchData(id)
			}
		},
		methods: {
			imgShow(url){
				var r = new RegExp(/http/g);  //构造正则表达式对象
				if(r.test(url))
					return url
				else
					return 'https://admin.rolumapp.com/upload/'+url
			},
			fetchData(id) {
				fetchDetail(id).then(response => {
					this.quData = response
					if(response.images){
						this.images = response.images.split("%")
						this.images.forEach((item,index) => {
							var r = new RegExp(/http/g);  //构造正则表达式对象
							if(r.test(item)){
							}
							else{
								this.images[index] = 'https://admin.rolumapp.com/upload/'+item
							}
						})
					}
					this.quData.answerList.forEach((an) => {
						// 解析数量
						if (an.analysis) {
							this.analysisCount += 1
						}

						// 用户选定的
						if (an.isRight) {
							if (this.quData.quType === 1) {
								this.radioValues = an.id
							} else {
								this.multiValues.push(an.id)
							}
						}
					})
				})
			},
			onCancel() {
				// this.$router.push({ name: 'ListTran' })
				this.$router.go(-1)
			}

		}
	}
</script>

<style scoped>

	.app-container {
		margin: 20px;
	}



	.qu-content {
		padding-bottom: 10px;
	}

	.qu-content div {
		line-height: 50px;
	}

	.qu-analysis p {
		color: #555;
		font-size: 14px
	}

	.qu-analysis-line {
		margin-top: 20px;
		border-bottom: #eee 1px solid
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
	}
</style>
