import { request } from '@/sujs/http'
/**
 * 题库详情
 * @param data
 */
export function fetchDetail(id) {
	return request({
		url:'/qu/qu/detail',
		method:"POST",
		data:{ id: id }
	})
}

